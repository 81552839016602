<header>
	<nav #nav>
		<ul class="menu right">

			<li class="lang {{localeId}}">
				<a *ngIf="localeId === 'fr'" href="{{currentPath}}" internal>
					<p>{{localeId}}</p>
				</a>
				<a *ngIf="localeId !== 'fr'" href="/{{localeId}}{{currentPath}}" internal>
					<p>{{localeId}}</p>
				</a>

				<ng-template ngFor let-lang [ngForOf]="languages">
					<a *ngIf="lang.code !== localeId && lang.code === 'fr'" href="{{currentPath}}" internal>
						<p>{{lang.code}}</p>
					</a>
					<a *ngIf="lang.code !== localeId && lang.code !== 'fr'" href="/{{lang.code}}{{currentPath}}" internal>
						<p>{{lang.code}}</p>
					</a>
				</ng-template>
			</li>
		</ul>
	</nav>
	<section id="title">
		<div>
			<a routerLink="/" class="brand-logo">
				<img  class="lazyload" data-src="assets/img/header/logo_ALYSEO.svg" alt="Alyseo">
			</a>
			<h1 i18n>Politique de&nbsp;gestion des&nbsp;données personnelles et&nbsp;de&nbsp;la&nbsp;vie&nbsp;privée</h1>
			<p><b i18n>Dernière mise à jour :</b> 15/11/2022</p>
		</div>
		<div>
			<img  class="lazyload" data-src="assets/img/header/privacy_illu.svg" alt="Woman choosing her privacy settings">
		</div>
	</section>
</header>
